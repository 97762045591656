<template>
    <div style="margin: 30px;line-height: 30px;text-align: left;font-family: 'Source Han Sans SC';">
        <!-- <p> -->
        <p class="MsoNormal" align="center" style="text-align:center;margin-bottom: 30px">
            <b>企修通平台知识产权声明</b>
        </p>
        <p class="MsoNormal">
            <span></span>
        </p>
        <p class="MsoListParagraph" style="text-indent:21.2pt;">
            为保障知识产权各权益方的合法权益，根据国家有关知识产权相关的法律法规，企修通平台对平台相关知识产权进行声明。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;text-indent:21.2pt;">
            一、 企修通平台侵权行为管理制度适用于企修通平台内（包括企修通渠道商端、企修通服务商端、企修通客户端、企修通用户端、企修通工程师端）的所有用户。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;text-indent:21.2pt;">
            二、 侵犯商业秘密行为：未经商业秘密所有人同意，使用或披露他方商业秘密，或者通过不正当途径获取他方商业秘密并使用或披露的行为。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;text-indent:21.2pt;">
            三、 企修通平台经营者对企修通平台内的所有信息内容（除特别注明信息来源或由他方输入的信息外）包括但不限于文字表述及其组合、图标、图饰、图表、色彩、版面设计、数据等均享有完整的著作权、专利权或商标权等相关权利，并受《中华人民共和国著作权法》等相关法律法规和中国加入的所有知识产权方面的国际条约、国际公约等的保护。本条中的“他方”包含平台用户。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;text-indent:21.2pt;">
            四、 企修通平台经营者对用户自行上传的内容不承担任何法律责任，任何第三方均可对企修通平台的内容进行监督，若发现存在侵犯任何第三人著作权等合法权益的内容，请及时告知，企修通平台经营者一经查实将在第一时间采取删除、屏蔽、断开链接、终止交易和服务等必要措施。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;text-indent:21.2pt;">
            五、 未经企修通平台经营者书面许可，对于企修通平台上的内容，任何单位或个人不得擅自复制、链接、非法使用或转载，不得以任何方式建立镜像站点。否则，企修通平台经营者将采取法律手段追究侵权者的责任。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;text-indent:21.3pt;">
            六、 如涉嫌侵权主体为企修通平台的，请权利人第一时间联系企修通平台经营者，并按照权利请求的措施和步骤提供初步证据，企修通平台经营者核实后将妥善处理。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;text-indent:21.3pt;">
            七、 根据《信息网络传播权保护条例》的规定，权利人应当对其权利通知的真实性负责；服务对象（提交反请求的人或者单位）应当对其反请求负责。如果您的“权利请求”或者“反请求”的陈述失实，您将承担对由此造成的全部法律责任（包括但不限于赔偿各种费用及律师费）。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;text-indent:21.3pt;">
            八、 企修通平台经营者将按照相关法律规定公示收到的上述条款收到的通知、声明及处理结果。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;text-indent:21.3pt;">
            九、 维权具体措施和步骤如下：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            （一）权利请求<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            任何个人或单位如果同时符合以下两个条件：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            <span>1</span>、是某一信息的权利所有人；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            <span>2</span>、企修通平台上的信息侵犯了该信息的权利。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            请上述个人或单位务必联系企修通工作人员，按照企修通工作人员的提示提供证明材料。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            （二）为了有效处理上述个人或单位的权利请求，请使用以下格式（包括各条款的序号）：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            <span>1</span>、请提供具体的联络信息，包括姓名、身份证或护照复印件（对自然人）、营业执照复印件（对单位）、通信地址、电话号码、传真和电子邮件；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            <span>2</span>、请完整、准确地指明涉嫌侵权行为的网页地址；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            <span>3</span>、请提供构成侵权的初步证明材料，谨此提示如以下材料可能会构成初步证明：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            （<span>1</span>）
            由人民法院出具的对侵权事实认定的判决书；<span>&nbsp; </span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            （<span>2</span>）
            由国家商标局、版权局等机构颁发的对涉嫌遭受侵权权利的权属证明；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            （<span>3</span>）
            对涉嫌遭受侵权内容的相关源文件或创作交易经过材料，包括但不限于协议合同、转账凭证、沟通记录等；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            （<span>4</span>）对涉嫌侵权事实的举证。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            <span>4</span>、在该权利通知落款处亲笔签名，如果是依法成立的机构或组织，请加盖公章。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            十、
            企修通的权利和义务<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            （<span>1</span>）
            企修通有义务接受投诉，并对涉嫌的侵权行为进行调查和说明。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            （<span>2</span>）
            企修通有权利要求权利主张方提供证明材料。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            （<span>3</span>）
            企修通有权根据单方认定的侵权情节采用警告、限期整改、删除侵权内容、清退等方式进行处理，情节严重者可移交司法机关处理。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            用户因侵权行为造成的一切经济损失由用户自行承担，本网站将不承担任何法律责任。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            （4） 企修通因用户侵权行为而遭受损失的，侵权用户应承担赔偿责任（包括但不限于处理此侵权事件所支出的律师费、差旅费等）。<span></span>
        </p>
        <p class="MsoListParagraph" style="text-indent:0cm;">
            <span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            附则<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            一、本制度自发布之日起执行。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            二、本制度修订权及最终解释权归企修通所有。<span></span>
        </p>
        <p class="MsoListParagraph" align="right" style="margin-left:21.3pt;text-align:right;text-indent:0cm;">
            重庆企修通网络科技有限公司<span></span>
        </p>
        <p class="MsoNormal">
            <span></span>
        </p>
        <!-- </p> -->
        <p>
            <br />
        </p>
        <div style="line-height: 30px;cursor: pointer;color: blue"><a href="/word/企修通平台知识产权声明.docx">企修通平台知识产权声明附件下载</a></div>

    </div>
</template>

<script>
    export default {
        name: "IntellectualProperty"
    }
</script>

<style scoped>

</style>
