import { render, staticRenderFns } from "./CooperationAgreement.vue?vue&type=template&id=4b23ae58&scoped=true&"
import script from "./CooperationAgreement.vue?vue&type=script&lang=js&"
export * from "./CooperationAgreement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b23ae58",
  null
  
)

export default component.exports