<template>
    <div style="margin: 30px;line-height: 30px">
        <p class="MsoNormal" align="center" style="text-align:center;">
            <b>企修通平台账户管理规范</b><b></b>
        </p>
        <p class="MsoNormal">
            <b></b>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:21.25pt;text-align:left;text-indent:-21.25pt;">
            1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>总则<span></span></b>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:21.2pt;">
            为了维护企修通平台和谐的商业氛围，保障用户账号相关的合法权益，维护企修通平台正常运营秩序，根据《企修通平台（服务商<span>/</span>渠道商）合作协议》及《企修通平台用户行为规范》，制定本规范。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:21.2pt;">
            账户的权利所有人（即用户），应在严格遵循本规范的前提下，进行账户地获取，使用、管理及注销，企修通平台内所有用户在适用本规范上一律平等。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:21.25pt;text-align:left;text-indent:-21.25pt;">
            2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>账户获取<span></span></b>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:1.0cm;text-align:left;text-indent:-1.0cm;">
            <b>2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>企业主账号的获取<span></span></b>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:21.2pt;">
            企业与企修通平台经营者达成协议后，严格遵守企修通平台的注册流程，企修通平台经营者依照注册流程设置完毕后，企业则可以获取到企业账号。由于企业账号的特殊性，企业账号以开户时提交的该企业联系人的主账号形式存在。（若开户时未提交该企业联系人的联系方式，则账号无法生成，若之后需要生成账号则补充联系人方式即可。）<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:21.2pt;">
            主账号拥有在该企业账号的最高使用权限。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:1.0cm;text-align:left;text-indent:-1.0cm;">
            <b>2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>子账号的获取<span></span></b>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:21.2pt;">
            子账号是由个人所在的企业依据个人向企业提供的信息资料（包括但不限于手机号、真实姓名等）进行开设，需联系企修通（运营对接人）参加开通账号考试，考试通过后由企修通（运营对接人）开通子账号，该子账号即可登录对应的企修通平台。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:0cm;">
            <b><span>2.3 </span></b><span>&nbsp;</span>派单员、工程师、业务员账号需联系企修通（运营对接人）参加开通账号考试，考试通过后由企修通（运营对接人）开通相关账号。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:0cm;">
            <b><span>2.4</span></b><span>&nbsp; </span>不得以虚假信息或冒用社会机构、名人及其他第三方注册账号。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:21.25pt;text-align:left;text-indent:-21.25pt;">
            3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>账户的使用和管理<span></span></b>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:1.0cm;text-align:left;text-indent:-1.0cm;">
            <b>3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>企业主账号的使用和管理<span></span></b>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:21.2pt;">
            企业可以通过主账号登录企业账户使用企修通平台相关服务，同时，也可以对企业账户的相关信息进行管理，包括但不限于企业信息、业务信息的调整。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:21.2pt;">
            由于企业主账号的信息代表着企业账户，企修通平台不允许企业自行修改企业主账号的信息。企业如必须进行修改，则需要通过书面形式向企修通平台经营者进行申请，申请通过后，将进行变更。变更后，企业新主账号将拥有企业原主账号所使用的一切服务，原主账号将失去所有权限。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:1.0cm;text-align:left;text-indent:-1.0cm;">
            <b>3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>子账号的使用和管理<span></span></b>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:21.2pt;">
            个人获得子账号后，可以通过子账号登录到企修通平台使用平台相关服务。个人也可以对子账户信息进行管理，但因企修通平台用户账户设立的特殊性，个人绝大部分信息的修改需要通过企业的主账号才能进行，具体哪些信息可以自行修改或需要企业主账号进行修改，企修通平台将会通过程序进行设定。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:1.0cm;text-align:left;text-indent:-1.0cm;">
            <b>3.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>派单员、工程师、业务员账号的使用和管理<span></span></b>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:21.2pt;">
            派单员、工程师、业务员获得账号后，可以通过账号登录到企修通平台使用平台相关服务。但因企修通平台用户账户设立的特殊性，大部分信息的修改需要通过企业的主账号才能进行，具体哪些信息可以自行修改或需要企业主账号进行修改，企修通平台将会通过程序进行设定。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:21.25pt;text-align:left;text-indent:-21.25pt;">
            4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>账户的安全<span></span></b>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:0cm;text-align:left;text-indent:21.3pt;">
            4.1&nbsp;&nbsp;&nbsp;&nbsp;
            用户须自行负责账号和密码的安全，且须对在该账号下的所有行为（包括但不限于网上点击同意各类协议、规则、参与交易等）承担责任。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:0cm;text-align:left;text-indent:21.3pt;">
            4.2&nbsp;&nbsp;&nbsp;&nbsp;
            用户有权根据需要更改账号密码。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:0cm;text-align:left;text-indent:21.3pt;">
            4.3&nbsp;&nbsp;&nbsp;&nbsp;
            因用户的过错或其他非归于企修通平台的原因所进行的行为导致的任何损失由用户自行承担，该行为包括但不限于：<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:70.9pt;text-align:left;text-indent:-1.0cm;">
            4.3.1&nbsp;&nbsp;&nbsp;&nbsp;
            不按照交易提示操作；<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:70.9pt;text-align:left;text-indent:-1.0cm;">
            4.3.2&nbsp;&nbsp;&nbsp;&nbsp;
            未及时进行交易操作；<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:70.9pt;text-align:left;text-indent:-1.0cm;">
            4.3.3&nbsp;&nbsp;&nbsp;&nbsp;
            遗忘或泄漏密码；<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:70.9pt;text-align:left;text-indent:-1.0cm;">
            4.3.4&nbsp;&nbsp;&nbsp;&nbsp;
            密码被他人破解；<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:70.9pt;text-align:left;text-indent:-1.0cm;">
            4.3.5&nbsp;&nbsp;&nbsp;&nbsp;
            用户使用的计算机被他人侵入。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:0cm;text-align:left;text-indent:21.3pt;">
            4.4&nbsp;&nbsp;&nbsp;&nbsp;
            企修通平台账户名对应的是一个用户的个人账号，同时还和用户的实际身份、用户个人信用，以及交易记录相关联，不得随意改动。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:0cm;text-align:left;text-indent:21.3pt;">
            4.5&nbsp;&nbsp;&nbsp;&nbsp;
            用户应当确保每次使用账户后，使用企修通平台提供的正确方式退出。如因非正确方式退出而导致的账户安全问题及其他延伸问题，由用户自行承担相关责任。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:21.25pt;text-align:left;text-indent:-21.25pt;">
            5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>账户的归属<span></span></b>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:0cm;text-align:left;text-indent:21.2pt;">
            5.1&nbsp;&nbsp;&nbsp;&nbsp;
            用户不得以任何形式擅自转让或授权他人使用自己在本网站的用户账号（实名认证通过后，不能进行变更）。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:0cm;text-align:left;text-indent:21.2pt;">
            5.2&nbsp;&nbsp;&nbsp;&nbsp;
            账号因所属人不明的情况产生纠纷或其他因司法行政等国家公职部门工作要求的情况下，企修通平台经营者有权对账号进行锁定处理。因纠纷情况锁定导致的任何损失及责任由发生纠纷的双方共同承担，锁定账号中尚有余额，将退至账号绑定的银行卡。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:0cm;text-align:left;text-indent:21.2pt;">
            5.3&nbsp;&nbsp;&nbsp;&nbsp;
            作为企修通平台经营者，为使您更好地使用企修通平台的各项服务，保障您的账户安全，企修通平台可要求您按企修通平台规则要求及我国法律规定完成实名认证。企修通平台用户账号归属以该账号实名认证信息为准，如您未进行实名认证的，将影响到您正常使用企修通平台的各项服务和权益保障。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:21.25pt;text-align:left;text-indent:-21.25pt;">
            6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>账户的注销<span></span></b>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:49.6pt;text-align:left;text-indent:-1.0cm;">
            <b>6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>企业账号的注销<span></span></b>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:21.2pt;">
            由于企修通平台的特殊性，企业账户的注销必须严格遵守《企修通平台退出规则》并书面形式向企修通平台经营者提前<span>15</span>个工作日进行通知。用户提出注销申请后并满足《企修通平台退出规则》条件后，企修通平台经营者会根据《企修通平台退出规则》相关流程注销企业账户。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:21.2pt;">
            企业账户注销后，该企业账户下的所有子账户将无法继续使用。企修通平台经营者将遵照平台规则进行相关数据的删除或隐匿处理，各类账户下存在余额的，余额将返还到绑定的相应银行卡中或等待用户在<span>30</span>个工作日内处理完毕后再行处理。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:49.6pt;text-align:left;text-indent:-1.0cm;">
            <b>6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>子账号的注销<span></span></b>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:21.2pt;">
            由于企修通平台用户账户设立的特殊性，子账号仅能通过其所在企业的主账号进行账户注销的操作。但企修通平台仍保留相关的渠道（包括但不限于线上程序、客服电话等）协助您进行账户的注销，您也可以通过企修通平台官方网站（<span>www.matefix.cn</span>）联系平台经营者进行处理。<span></span>
        </p>
        <p class="MsoListParagraph" align="left" style="text-align:left;text-indent:21.3pt;">
            <b><span>6.3 </span></b><b>派单员、工程师、业务员账号的注销</b><span></span>
        </p>
        <p class="MsoNormal" style="text-indent:21.0pt;">
            由于企修通平台用户账户设立的特殊性，子账号仅能通过其所在企业的主账号进行账户注销的操作。<b><span></span></b>
        </p>
        <p class="MsoNormal">
            <b><span></span></b>
        </p>
        <p class="MsoNormal">
            <b><span></span></b>
        </p>
        <p class="MsoNormal">
            <b><span>7&nbsp;&nbsp; </span></b><b>本规定自颁布之日起执行。<span></span></b>
        </p>
        <p class="MsoListParagraph" style="text-indent:0cm;">
            <b><span>8&nbsp;&nbsp; </span></b><b>本规定修订权及最终解释权归企修通所有。</b><span></span>
        </p>
        <p class="MsoListParagraph" align="right" style="margin-left:21.3pt;text-align:right;text-indent:0cm;">
            重庆企修通网络科技有限公司<span></span>
        </p>
        <p class="MsoNormal">
            <b><span></span></b>
        </p>
        <p>
            <br />
        </p>
        <div style="line-height: 30px;cursor: pointer;color: blue"><a href="/word/企修通平台账户管理规范.docx">企修通平台账户管理规范附件下载</a></div>

    </div>
</template>

<script>
    export default {
        name: "AccountManager"
    }
</script>

<style scoped>

</style>
